import $ from 'jquery';
import React from 'react';
import './comp_panchang.css';

export function Comp_Panchang(){
  
    return(<div class='widget category_list'>
      
      <div id='astro_widget'>
        <div id='astro_widget_content'></div>
      </div>
      <div id='avx_widget_148_wid' style={{ display: 'block' }}>
        <div
          id='avx_widget_148_wid_in'
          class='av_widlocator'
          style={{ display: 'block' }}
        >
          <a
            href='https://www.indianastrologysoftware.com/free-download-kundli-software.php'
            target='_blank'
          ></a>
        </div>
      </div>
    
    
  </div>);
} 
export default Comp_Panchang;