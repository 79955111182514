import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
export function Article_Listing(lsData){
    var appUrtPath=appGetUrlPath();
var listData=lsData;
//alert(JSON.stringify(listData));
let vGtitle="";
if(listData.length>0){
  //alert(JSON.stringify(listData[0]));
  if(typeof listData[0]["g_title"]==="undefined"){
    vGtitle=listData[0]["menu"];
  }else{
    vGtitle=listData[0]["g_title"];
  }
  
}
let bgAssign="";

  

    var strResp="";
    let colset="col-lg-3 col-md-6 col-sm-6 col-xs-6";
    let imgProperty="";
  for(var i=0;i<listData.length;i++){
    if(listData[i]["col12"]!==""){
      let bgAssignTmp=listData[i]["col12"];
      if(listData[i]["col12"]==="default-image"){
        bgAssign="bg-area1";
      }
      
    }
    
    var imgPath="default_articles.jpg"; 
    var urlTitle= lsData[i]["col1"];
    urlTitle=urlTitle.replace("?","_");
    urlTitle=urlTitle.replace(" ","-");
    if(listData[i]["col4"]!=""){
     imgPath=listData[i]["col4"];
      }
     let landingUrl="../blog/"+urlTitle+"?rf="+lsData[i]["e_id"];
     if(listData[i]["col7"]!==""){
       landingUrl="../"+listData[i]["col7"];
     }
     if(listData[i]["col13"]!==""){
      colset=listData[i]["col13"];
     }
     
     if(listData[i]["col10"]!==""){
      
      if(listData[i]["col10"]==="round-image"){
        imgProperty="round-image";
      }
     }

     let videoPlayer="";
     let contentThumbnai="";
     if(lsData[i]["col6"]!==""){
      contentThumbnai="<iframe class=\"img-thumbnail hover-image\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen width=\"100%\""+
      "src=\""+lsData[i]["col6"]+"\">"+
      "</iframe>";
    }else{
      contentThumbnai="<img src=\""+appUrtPath+imgPath+"\" class=\"w-100 "+imgProperty+"\" alt=\"Product\" />";
    }

      strResp+="<div class=\""+colset+"\">"+
      "<div class=\"box-smpad product mr-4 mb-5\">"+
      "<form>"+
            "<div class=\"thumb\">"+
              "<a href=\""+landingUrl+"\" class=\"image\">"+
                  contentThumbnai+
                //   "<img class=\"hover-image\" src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
              "</a>"+
          "</div>"+
          "<div class=\"content\">"+
              "<h5 class=\"title\"><a href=\""+landingUrl+"\">"+listData[i]["col1"]+"</a></h5>"+
            //   "<span class=\"price\">"+
            //       "<span class=\"new\">"+listData[i]["col2"]+"</span>"+
            //   "</span>"+
          "</div>"+
      "</form>"+"</div>"+
  "</div>";
  }
  var strHeader="<div class=\"shop-category-area "+bgAssign+" pb-100px pt-70px\">"+
  "<div class=\"container\">"+
      "<div class=\"row\">"+
          "<div class=\"col-lg-12 order-lg-last111 col-md-12 order-md-first\">"+
          "<div class=\"heading page__title-bar\">"+
          "<h3 class=\"title\">"+vGtitle+"</h3>"+
      "</div>"+
          "</div>"+
          "<div class=\"shop-bottom-area\">"+
          "<div class=\"row\">";
   var strFooter="</div></div></div></div></div>";
  return strHeader+strResp+strFooter;

}
export default Article_Listing;