import React from 'react';
import {appGetUrlPath} from '../function_lib/common_lib';
//import './image_gallery.css';
import '../assets/css/magnific-popup.css';
import '../assets/js/jquery.magnific-popup.js';
export function Video_Gallery(lsData){
    var appUrtPath=appGetUrlPath();
//alert(JSON.stringify(lsData));
    var title="";
if(lsData.length!=0){
title=lsData[0]["g_title"];
}

        var strHeader="<div class=\"shop-category-area pt-50px\">"+
        "<div class=\"container\">"+
            "<div class=\"row\">"+
                "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
                "<div class=\"section-title heading text-center mb-11\">"+
                "<h2 class=\"title\">"+title+"</h2>"+
            "</div>"+
                "<div class=\"shop-bottom-area\">"+
                "<div class=\"row\">";
         var strFooter="</div></div></div></div></div></div>";
    
        var strResp="";
      for(var i=0;i<lsData.length;i++){
        let videoPlayer="";
          var strImages=lsData[i]["col4"];
          var arrImages=strImages.split(',');
          if(lsData[i]["col6"]!==""){
            videoPlayer="<iframe class=\"img-thumbnail hover-image\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen width=\"100%\""+
            "src=\""+lsData[i]["col6"]+"\">"+
            "</iframe>";
          }
          for(var j=0;j<arrImages.length;j++){
            if(videoPlayer===""){
                strResp+="<div class=\"col-lg-3  col-md-4 col-sm-6 col-xs-6\" data-aos=\"fade-up\" data-aos-delay=\"200\">"+
                "<div class=\"product mb-5\">"+
                    "<div class=\"thumb\">"+
                           "<video class=\"img-thumbnail hover-image \" height=\"153\" controls type=\"video/mp4\"  src=\""+appUrtPath+arrImages[j]+"\"></video>"+
                         "</div>"+
                "</div>"+
            "</div>";
            }else{
                strResp+="<div class=\"col-lg-3  col-md-4 col-sm-6 col-xs-6\" data-aos=\"fade-up\" data-aos-delay=\"200\">"+
                "<div class=\"product mb-5\">"+
                    "<div class=\"thumb border-box\">"+
                           videoPlayer+
                           "<p class=\"blog-detail-text\"><b>"+lsData[i]["col1"]+"</b></p>"+
                         "</div>"+
                         
                "</div>"+
            "</div>";

            }
          
          }
      }
      return strHeader+strResp+strFooter;
}
export default Video_Gallery;